/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class UserToken {
  constructor(token) {
    if (token) {
      this['token'] = token;
    }


  }

  static typeDescription() {
    return "UserToken";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserToken();
      if (data.hasOwnProperty('token')) {
        obj['token'] = ApiClient.convertToType(data['token'], 'String');
      }
    }
    return obj;
  }


  token = "";




  getToken() {
    return this.token;
  }

  setToken(token) {
    this['token'] = token;
  }


}