/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddCustomerStock from '../model/AddCustomerStock';
import Anomaly from '../model/Anomaly';
import AuthenticationToken from '../model/AuthenticationToken';
import CreateCustomerCollaborator from '../model/CreateCustomerCollaborator';
import Customer from '../model/Customer';
import CustomerShort from '../model/CustomerShort';
import CustomerStocks from '../model/CustomerStocks';
import CustomerStocksFilterRequest from '../model/CustomerStocksFilterRequest';
import CustomerStocksMovement from '../model/CustomerStocksMovement';
import Error from '../model/Error';
import MoveCustomerStock from '../model/MoveCustomerStock';
import PaymentSurcharge from '../model/PaymentSurcharge';
import Quotation from '../model/Quotation';
import Quotations from '../model/Quotations';
import RemoveCustomerStock from '../model/RemoveCustomerStock';
import Specification from '../model/Specification';
import SpecificationTemplate from '../model/SpecificationTemplate';
import Type from '../model/Type';
import User from '../model/User';
import UserToken from '../model/UserToken';


export default class UserApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  addCustomerStockWithHttpInfo(addCustomerStock) {
    let postBody = addCustomerStock;

    // verify the required parameter 'addCustomerStock' is set
    if (addCustomerStock === undefined || addCustomerStock === null) {
      throw new Error("Missing the required parameter 'addCustomerStock' when calling addCustomerStock");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = CustomerStocksMovement;

    return this.apiClient.callApi(
      '/customer/stocks/add', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  addCustomerStock(addCustomerStock) {
    return this.addCustomerStockWithHttpInfo(addCustomerStock).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  completeCollaboratorInvitationWithHttpInfo(login, password, token) {
    let postBody = null;

    // verify the required parameter 'login' is set
    if (login === undefined || login === null) {
      throw new Error("Missing the required parameter 'login' when calling completeCollaboratorInvitation");
    }

    // verify the required parameter 'password' is set
    if (password === undefined || password === null) {
      throw new Error("Missing the required parameter 'password' when calling completeCollaboratorInvitation");
    }

    // verify the required parameter 'token' is set
    if (token === undefined || token === null) {
      throw new Error("Missing the required parameter 'token' when calling completeCollaboratorInvitation");
    }


    let pathParams = {
    };
    let queryParams = {
      'login': login,
      'password': password,
      'token': token
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/completeInvitation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  completeCollaboratorInvitation(login, password, token) {
    return this.completeCollaboratorInvitationWithHttpInfo(login, password, token).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCollaboratorWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'email': opts['email'],
      'firstName': opts['firstName'],
      'lastName': opts['lastName'],
      'callbackUrl': opts['callbackUrl']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/users', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCollaborator(opts) {
    return this.createCollaboratorWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCustomerWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createCustomer");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCustomer(content) {
    return this.createCustomerWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCustomerCollaboratorWithHttpInfo(customerId, createCustomerCollaborator) {
    let postBody = createCustomerCollaborator;

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling createCustomerCollaborator");
    }

    // verify the required parameter 'createCustomerCollaborator' is set
    if (createCustomerCollaborator === undefined || createCustomerCollaborator === null) {
      throw new Error("Missing the required parameter 'createCustomerCollaborator' when calling createCustomerCollaborator");
    }


    let pathParams = {
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/customer/{customerId}/createCollaborator', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCustomerCollaborator(customerId, createCustomerCollaborator) {
    return this.createCustomerCollaboratorWithHttpInfo(customerId, createCustomerCollaborator).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createSpecificationFromTemplateWithHttpInfo(type) {
    let postBody = type;

    // verify the required parameter 'type' is set
    if (type === undefined || type === null) {
      throw new Error("Missing the required parameter 'type' when calling createSpecificationFromTemplate");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Specification;

    return this.apiClient.callApi(
      '/specification/fromTemplate', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createSpecificationFromTemplate(type) {
    return this.createSpecificationFromTemplateWithHttpInfo(type).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createUserWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling createUser");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/user', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createUser(body) {
    return this.createUserWithHttpInfo(body).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteCustomerWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/customer', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteCustomer() {
    return this.deleteCustomerWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteUserWithHttpInfo(userId) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling deleteUser");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/user/{userId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteUser(userId) {
    return this.deleteUserWithHttpInfo(userId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCustomerWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCustomer() {
    return this.getCustomerWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCustomerAnomaliesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'severity': opts['severity'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'origin': opts['origin'],
      'actionStatus': opts['actionStatus'],
      'isOpen': opts['isOpen']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Anomaly];

    return this.apiClient.callApi(
      '/customer/anomalies', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCustomerAnomalies(opts) {
    return this.getCustomerAnomaliesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCustomerByIDWithHttpInfo(customerId) {
    let postBody = null;

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling getCustomerByID");
    }


    let pathParams = {
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer/{customerId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCustomerByID(customerId) {
    return this.getCustomerByIDWithHttpInfo(customerId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCustomersWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'name': opts['name'],
      'siret': opts['siret'],
      'vatNumber': opts['vatNumber'],
      'erpId': opts['erpId']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [CustomerShort];

    return this.apiClient.callApi(
      '/customers', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCustomers(opts) {
    return this.getCustomersWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCustomersStocksWithHttpInfo(filter, opts) {
    opts = opts || {};
    let postBody = filter;

    // verify the required parameter 'filter' is set
    if (filter === undefined || filter === null) {
      throw new Error("Missing the required parameter 'filter' when calling getCustomersStocks");
    }


    let pathParams = {
    };
    let queryParams = {
      'stockMovements': opts['stockMovements']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [CustomerStocks];

    return this.apiClient.callApi(
      '/customer/stocks', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCustomersStocks(filter, opts) {
    return this.getCustomersStocksWithHttpInfo(filter, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotationsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'trackingId': opts['trackingId'],
      'ownerId': opts['ownerId'],
      'customerId': opts['customerId'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'type': opts['type'],
      'name': opts['name'],
      'reference': opts['reference'],
      'isPublic': opts['isPublic']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Quotation];

    return this.apiClient.callApi(
      '/quotations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotations(opts) {
    return this.getQuotationsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotationsPaymentSurchargesWithHttpInfo(quotations) {
    let postBody = quotations;

    // verify the required parameter 'quotations' is set
    if (quotations === undefined || quotations === null) {
      throw new Error("Missing the required parameter 'quotations' when calling getQuotationsPaymentSurcharges");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [PaymentSurcharge];

    return this.apiClient.callApi(
      '/quotations/paymentSurchages', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotationsPaymentSurcharges(quotations) {
    return this.getQuotationsPaymentSurchargesWithHttpInfo(quotations).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getSpecificationsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'trackingId': opts['trackingId'],
      'id': opts['id'],
      'ownerId': opts['ownerId'],
      'customerId': opts['customerId'],
      'name': opts['name'],
      'reference': opts['reference'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'type': opts['type']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Specification];

    return this.apiClient.callApi(
      '/specifications', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getSpecifications(opts) {
    return this.getSpecificationsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getTemplatesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'type': opts['type']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [SpecificationTemplate];

    return this.apiClient.callApi(
      '/templates', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getTemplates(opts) {
    return this.getTemplatesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getUserWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/user', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getUser() {
    return this.getUserWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getUserAnomaliesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'severity': opts['severity'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'origin': opts['origin'],
      'actionStatus': opts['actionStatus'],
      'isOpen': opts['isOpen']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Anomaly];

    return this.apiClient.callApi(
      '/user/anomalies', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getUserAnomalies(opts) {
    return this.getUserAnomaliesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getUserByIdWithHttpInfo(userId) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getUserById");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/user/{userId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getUserById(userId) {
    return this.getUserByIdWithHttpInfo(userId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getUserCustomerWithHttpInfo(userId) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getUserCustomer");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/user/{userId}/customer', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getUserCustomer(userId) {
    return this.getUserCustomerWithHttpInfo(userId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getUsersWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'login': opts['login'],
      'firstName': opts['firstName'],
      'lastName': opts['lastName'],
      'email': opts['email']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [User];

    return this.apiClient.callApi(
      '/users', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getUsers(opts) {
    return this.getUsersWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  joinCustomerWithHttpInfo(userId, customerId) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling joinCustomer");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling joinCustomer");
    }


    let pathParams = {
      'userId': userId,
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/user/{userId}/joinCustomer/{customerId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  joinCustomer(userId, customerId) {
    return this.joinCustomerWithHttpInfo(userId, customerId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  loginUserWithHttpInfo(username, password, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'username' is set
    if (username === undefined || username === null) {
      throw new Error("Missing the required parameter 'username' when calling loginUser");
    }

    // verify the required parameter 'password' is set
    if (password === undefined || password === null) {
      throw new Error("Missing the required parameter 'password' when calling loginUser");
    }


    let pathParams = {
    };
    let queryParams = {
      'username': username,
      'password': password,
      'transferAnonymousSpecifications': opts['transferAnonymousSpecifications']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = AuthenticationToken;

    return this.apiClient.callApi(
      '/login', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  loginUser(username, password, opts) {
    return this.loginUserWithHttpInfo(username, password, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  logoutUserWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/logout', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  logoutUser() {
    return this.logoutUserWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  mergeCustomersWithHttpInfo(customerId1, customerId2) {
    let postBody = null;

    // verify the required parameter 'customerId1' is set
    if (customerId1 === undefined || customerId1 === null) {
      throw new Error("Missing the required parameter 'customerId1' when calling mergeCustomers");
    }

    // verify the required parameter 'customerId2' is set
    if (customerId2 === undefined || customerId2 === null) {
      throw new Error("Missing the required parameter 'customerId2' when calling mergeCustomers");
    }


    let pathParams = {
      'customerId1': customerId1,
      'customerId2': customerId2
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer/merge/{customerId1}/in/{customerId2}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  mergeCustomers(customerId1, customerId2) {
    return this.mergeCustomersWithHttpInfo(customerId1, customerId2).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  moveCustomerStockWithHttpInfo(moveCustomerStock) {
    let postBody = moveCustomerStock;

    // verify the required parameter 'moveCustomerStock' is set
    if (moveCustomerStock === undefined || moveCustomerStock === null) {
      throw new Error("Missing the required parameter 'moveCustomerStock' when calling moveCustomerStock");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/customer/stocks/move', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  moveCustomerStock(moveCustomerStock) {
    return this.moveCustomerStockWithHttpInfo(moveCustomerStock).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeCustomerStockWithHttpInfo(removeCustomerStock) {
    let postBody = removeCustomerStock;

    // verify the required parameter 'removeCustomerStock' is set
    if (removeCustomerStock === undefined || removeCustomerStock === null) {
      throw new Error("Missing the required parameter 'removeCustomerStock' when calling removeCustomerStock");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/customer/stocks/remove', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeCustomerStock(removeCustomerStock) {
    return this.removeCustomerStockWithHttpInfo(removeCustomerStock).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  resetCollaboratorInvitationTokenWithHttpInfo(requestToken) {
    let postBody = null;

    // verify the required parameter 'requestToken' is set
    if (requestToken === undefined || requestToken === null) {
      throw new Error("Missing the required parameter 'requestToken' when calling resetCollaboratorInvitationToken");
    }


    let pathParams = {
    };
    let queryParams = {
      'requestToken': requestToken
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/resetCollaboratorInvitationToken', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  resetCollaboratorInvitationToken(requestToken) {
    return this.resetCollaboratorInvitationTokenWithHttpInfo(requestToken).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  resetPasswordResetTokenWithHttpInfo(requestToken) {
    let postBody = null;

    // verify the required parameter 'requestToken' is set
    if (requestToken === undefined || requestToken === null) {
      throw new Error("Missing the required parameter 'requestToken' when calling resetPasswordResetToken");
    }


    let pathParams = {
    };
    let queryParams = {
      'requestToken': requestToken
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/resetPasswordResetToken', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  resetPasswordResetToken(requestToken) {
    return this.resetPasswordResetTokenWithHttpInfo(requestToken).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  resetValidateEmailTokenWithHttpInfo(requestToken) {
    let postBody = null;

    // verify the required parameter 'requestToken' is set
    if (requestToken === undefined || requestToken === null) {
      throw new Error("Missing the required parameter 'requestToken' when calling resetValidateEmailToken");
    }


    let pathParams = {
    };
    let queryParams = {
      'requestToken': requestToken
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/resetValidateEmailToken', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  resetValidateEmailToken(requestToken) {
    return this.resetValidateEmailTokenWithHttpInfo(requestToken).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  sendAllInvitationEmailsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'callbackUrl': opts['callbackUrl']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/user/sendAllInvitations', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  sendAllInvitationEmails(opts) {
    return this.sendAllInvitationEmailsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  sendCollaboratorInvitationEmailWithHttpInfo(userId, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling sendCollaboratorInvitationEmail");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
      'email': opts['email'],
      'callbackUrl': opts['callbackUrl']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/user/{userId}/sendInvitation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  sendCollaboratorInvitationEmail(userId, opts) {
    return this.sendCollaboratorInvitationEmailWithHttpInfo(userId, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setCustomerWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setCustomer");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setCustomer(content) {
    return this.setCustomerWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setCustomerByIdWithHttpInfo(customerId, content) {
    let postBody = content;

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling setCustomerById");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setCustomerById");
    }


    let pathParams = {
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Customer;

    return this.apiClient.callApi(
      '/customer/{customerId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setCustomerById(customerId, content) {
    return this.setCustomerByIdWithHttpInfo(customerId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setUserWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setUser");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/user', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setUser(body) {
    return this.setUserWithHttpInfo(body).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setUserByIdWithHttpInfo(userId, body) {
    let postBody = body;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling setUserById");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setUserById");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = User;

    return this.apiClient.callApi(
      '/user/{userId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setUserById(userId, body) {
    return this.setUserByIdWithHttpInfo(userId, body).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  unregisteredLoginWithHttpInfo(userId) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling unregisteredLogin");
    }


    let pathParams = {
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = AuthenticationToken;

    return this.apiClient.callApi(
      '/user/{userId}/login', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  unregisteredLogin(userId) {
    return this.unregisteredLoginWithHttpInfo(userId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  userEmailValidationWithHttpInfo(emailToken) {
    let postBody = null;

    // verify the required parameter 'emailToken' is set
    if (emailToken === undefined || emailToken === null) {
      throw new Error("Missing the required parameter 'emailToken' when calling userEmailValidation");
    }


    let pathParams = {
    };
    let queryParams = {
      'emailToken': emailToken
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/validateEmail', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  userEmailValidation(emailToken) {
    return this.userEmailValidationWithHttpInfo(emailToken).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  userGenerateTokenWithHttpInfo(userId, type) {
    let postBody = null;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling userGenerateToken");
    }

    // verify the required parameter 'type' is set
    if (type === undefined || type === null) {
      throw new Error("Missing the required parameter 'type' when calling userGenerateToken");
    }


    let pathParams = {
      'userId': userId,
      'type': type
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = UserToken;

    return this.apiClient.callApi(
      '/user/{userId}/generateToken/{type}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  userGenerateToken(userId, type) {
    return this.userGenerateTokenWithHttpInfo(userId, type).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  userLoginRequestWithHttpInfo(email) {
    let postBody = null;

    // verify the required parameter 'email' is set
    if (email === undefined || email === null) {
      throw new Error("Missing the required parameter 'email' when calling userLoginRequest");
    }


    let pathParams = {
    };
    let queryParams = {
      'email': email
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/loginLost', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  userLoginRequest(email) {
    return this.userLoginRequestWithHttpInfo(email).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  userPasswordRequestWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'login': opts['login'],
      'email': opts['email']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/passwordLost', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  userPasswordRequest(opts) {
    return this.userPasswordRequestWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  userPasswordResetWithHttpInfo(requestToken, password) {
    let postBody = null;

    // verify the required parameter 'requestToken' is set
    if (requestToken === undefined || requestToken === null) {
      throw new Error("Missing the required parameter 'requestToken' when calling userPasswordReset");
    }

    // verify the required parameter 'password' is set
    if (password === undefined || password === null) {
      throw new Error("Missing the required parameter 'password' when calling userPasswordReset");
    }


    let pathParams = {
    };
    let queryParams = {
      'requestToken': requestToken,
      'password': password
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/resetPassword', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  userPasswordReset(requestToken, password) {
    return this.userPasswordResetWithHttpInfo(requestToken, password).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}