import React from "react";
import { Modal, Button } from "Elements";
import productTitles from "marketing/productPages";
import SquareButton from "components/SquareButton";
import marketingMessages from "marketing/messages";
import { injectIntl, FormattedMessage } from "react-intl";
import products from "products";
const soonProducts = products.filter((p) => p.soon).map((p) => p.slug);
const closedProducts = products.filter((p) => p.closed).map((p) => p.slug);

const NewProject = injectIntl((props) => {
  console.log("newproject render", props);
  let nProductTitles = [...productTitles];
  if (props.emslabs) {
    nProductTitles = [
    ...nProductTitles,
    {
      slug: "emslabs",
      title: "EmsLabs"
    }];

  }
  const productButtons =
  <div
  // style={{ width: 540 }}
  className={
  "row mx-n2 new_project" + (
  props.emslabs ? " new_project-emslabs" : "") + (
  props.masonry ? " new_project-masonry" : "")
  }>

      {nProductTitles.map((product, i) =>
    <SquareButton
      masonry={props.masonry}
      key={i}
      product={product}
      productLink={props.productLink || ""}
      soon={soonProducts.indexOf(product.slug) >= 0}
      closed={closedProducts.indexOf(product.slug) >= 0}
      onClick={(e) => props.onHide ? props.onHide() : e} />

    )}
    </div>;

  if (!props.modal) {
    return productButtons;
  }
  return (
    <Modal show onHide={(e) => props.onHide(e)} size="lg">
      <Modal.Header toggle={(e) => props.onHide(e)}>
        {props.intl.formatMessage(marketingMessages.newProject)}
      </Modal.Header>
      <Modal.Body>
        <div className="px-sm-5 mx-sm-5">
          {props.text ? <p className="text-center">{props.text}</p> : null}
          {productButtons}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e) => props.onHide()}>
          {props.intl.formatMessage(marketingMessages.cancel)}
        </Button>
      </Modal.Footer>
    </Modal>);

});

export default NewProject;