import React from "react";
import { FormattedMessage } from "react-intl";
import Section from "marketing/components/Section";
import Button from "marketing/components/Button";
import SquareBenefits from "marketing/components/SquareBenefits";
import GalleryPreview from "components/GalleryPreview";
import products from "products";

import marketingMessages from "marketing/messages";
const soonProducts = products.filter((p) => p.soon).map((p) => p.slug);
const closedProducts = products.filter((p) => p.closed).map((p) => p.slug);

const ProductCover = (props) => {
  const { data = {}, page = {} } = props;
  const { id, title: pageTitle, description: pageDescription } = page;
  const { images = [], title, squares = [] } = data;
  console.log("ProductCover render", props);
  return (
    <Section className="page_cover">
      <div
        className="bg-primary"
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: "0",
          top: "0",
          opacity: id === "xray" ? "0.85" : "0.75"
        }}>
      </div>
      <div className="row">
        <div className="col-md pt-3">
          <div className="product_page_cover_gallery">
            <div>
              <img
                data-src={"/images/marketing/" + id + "/cover-icon.svg"}
                alt=""
                className="img-fluid lazyload" />

            </div>
            <h4>{pageTitle}</h4>
          </div>
          <GalleryPreview images={images} noFirst />
          {/* <div className="row">
             {images.slice(0, 4).map((image, i) => (
               <div
                 key={i}
                 className={
                   "col " + (i === 0 ? "pr-1" : i === 3 ? "pl-1" : "px-1")
                 }
               >
                 <div
                   style={{
                     height: "80px",
                     width: "100%",
                     cursor: "pointer",
                     overflow: "hidden",
                   }}
                   onClick={(e) => props.showModal(props.product.images, i)}
                 >
                   <img
                     style={{ width: "100%", minHeight: "80px" }}
                     src={image.src}
                     alt={image.alt}
                   />
                 </div>
               </div>
             ))}
            </div> */}
        </div>
        <div className="col-md">
          <div className="product_page_cover_intro">
            {title ?
            <h1>{title}</h1> :

            <h1>
                <FormattedMessage
                id="marketing.productCover.defaultTitle"
                defaultMessage="Entrust your {product} project to our factory"
                value={{ product: pageTitle }} />

              </h1>
            }

            <p>{pageDescription}</p>
            <SquareBenefits squares={squares} />
            {soonProducts.indexOf(page.id) < 0 &&
            closedProducts.indexOf(page.id) < 0 ?
            [
            <span key={1} className="mr-3 mb-2 d-none d-lg-inline-block">
                    <Button to={"/" + id + "/start?tour=1"}>
                      <FormattedMessage {...marketingMessages.onlineDemo} />
                    </Button>
                  </span>,
            <span key={2} className="d-none d-lg-inline-block">
                    <Button to={"/" + id + "/start"}>
                      <FormattedMessage {...marketingMessages.startProject} />
                    </Button>
                  </span>] :

            null}
          </div>
        </div>
      </div>
    </Section>);

};

export default ProductCover;