/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Invoice {
  constructor() {


  }

  static typeDescription() {
    return "Invoice";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Invoice();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('erpId')) {
        obj['erpId'] = ApiClient.convertToType(data['erpId'], 'Number');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('erpDocumentUrl')) {
        obj['erpDocumentUrl'] = ApiClient.convertToType(data['erpDocumentUrl'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('invoiceDate')) {
        obj['invoiceDate'] = ApiClient.convertToType(data['invoiceDate'], 'Date');
      }
      if (data.hasOwnProperty('paymentMethod')) {
        obj['paymentMethod'] = ApiClient.convertToType(data['paymentMethod'], 'String');
      }
      if (data.hasOwnProperty('dueAmount')) {
        obj['dueAmount'] = ApiClient.convertToType(data['dueAmount'], 'Number');
      }
      if (data.hasOwnProperty('paidAmount')) {
        obj['paidAmount'] = ApiClient.convertToType(data['paidAmount'], 'Number');
      }
      if (data.hasOwnProperty('remainingDues')) {
        obj['remainingDues'] = ApiClient.convertToType(data['remainingDues'], 'Number');
      }
      if (data.hasOwnProperty('paymentDeadline')) {
        obj['paymentDeadline'] = ApiClient.convertToType(data['paymentDeadline'], 'Date');
      }
      if (data.hasOwnProperty('isPaid')) {
        obj['isPaid'] = ApiClient.convertToType(data['isPaid'], 'Boolean');
      }
      if (data.hasOwnProperty('orderId')) {
        obj['orderId'] = ApiClient.convertToType(data['orderId'], 'String');
      }
      if (data.hasOwnProperty('isVATEnabled')) {
        obj['isVATEnabled'] = ApiClient.convertToType(data['isVATEnabled'], 'Boolean');
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  erpId = undefined;

  reference = undefined;

  erpDocumentUrl = undefined;

  creationDate = undefined;

  invoiceDate = undefined;

  paymentMethod = undefined;

  dueAmount = undefined;

  paidAmount = undefined;

  remainingDues = undefined;

  paymentDeadline = undefined;

  isPaid = undefined;

  orderId = undefined;

  isVATEnabled = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getErpId() {
    return this.erpId;
  }

  setErpId(erpId) {
    this['erpId'] = erpId;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getErpDocumentUrl() {
    return this.erpDocumentUrl;
  }

  setErpDocumentUrl(erpDocumentUrl) {
    this['erpDocumentUrl'] = erpDocumentUrl;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getInvoiceDate() {
    return this.invoiceDate;
  }

  setInvoiceDate(invoiceDate) {
    this['invoiceDate'] = invoiceDate;
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  setPaymentMethod(paymentMethod) {
    this['paymentMethod'] = paymentMethod;
  }

  getDueAmount() {
    return this.dueAmount;
  }

  setDueAmount(dueAmount) {
    this['dueAmount'] = dueAmount;
  }

  getPaidAmount() {
    return this.paidAmount;
  }

  setPaidAmount(paidAmount) {
    this['paidAmount'] = paidAmount;
  }

  getRemainingDues() {
    return this.remainingDues;
  }

  setRemainingDues(remainingDues) {
    this['remainingDues'] = remainingDues;
  }

  getPaymentDeadline() {
    return this.paymentDeadline;
  }

  setPaymentDeadline(paymentDeadline) {
    this['paymentDeadline'] = paymentDeadline;
  }

  getIsPaid() {
    return this.isPaid;
  }

  setIsPaid(isPaid) {
    this['isPaid'] = isPaid;
  }

  getOrderId() {
    return this.orderId;
  }

  setOrderId(orderId) {
    this['orderId'] = orderId;
  }

  getIsVATEnabled() {
    return this.isVATEnabled;
  }

  setIsVATEnabled(isVATEnabled) {
    this['isVATEnabled'] = isVATEnabled;
  }


  static PaymentMethodEnum = {
    "cb": "cb",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "other": "other"
  };

}