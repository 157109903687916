import ModuleLoader from "ModuleLoader";

let products = [
{
  slug: "pcba",
  path: "PCBA"
},
{
  slug: "wiring",
  path: "Wiring",
  closed: true
},
{
  slug: "pcb",
  path: "Pcb"
},
{
  slug: "enclosure",
  path: "Enclosure",
  soon: true
},
{
  slug: "coating",
  path: "Coating",
  closed: true
},
{
  slug: "potting",
  path: "Potting"
},
{
  slug: "xray",
  path: "XRay",
  closed: true
},
{
  slug: "assembly",
  path: "Assembly",
  soon: true
},
{
  slug: "rework",
  path: "Rework"
},
{
  slug: "engineering",
  path: "Engineering"
},
{
  slug: "supply",
  path: "Supply"
  //closed: true,
}];


export function isProductClosed(type) {
  const product = products.find((p) => p.slug === type);
  return product && product.closed;
}

export default products.map((p) =>
Object.assign({}, p, {
  component: ModuleLoader(p.path)
})
);