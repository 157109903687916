/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';
import QuoteTechnicalContactIdsByQuotationIds from './QuoteTechnicalContactIdsByQuotationIds';

export default class Quote {
  constructor() {


  }

  static typeDescription() {
    return "Quote";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Quote();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('validationDate')) {
        obj['validationDate'] = ApiClient.convertToType(data['validationDate'], 'String');
      }
      if (data.hasOwnProperty('validityDaysDuration')) {
        obj['validityDaysDuration'] = ApiClient.convertToType(data['validityDaysDuration'], 'Number');
      }
      if (data.hasOwnProperty('expirationDate')) {
        obj['expirationDate'] = ApiClient.convertToType(data['expirationDate'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('finalCustomerId')) {
        obj['finalCustomerId'] = ApiClient.convertToType(data['finalCustomerId'], 'String');
      }
      if (data.hasOwnProperty('finalCustomerName')) {
        obj['finalCustomerName'] = ApiClient.convertToType(data['finalCustomerName'], 'String');
      }
      if (data.hasOwnProperty('creatorId')) {
        obj['creatorId'] = ApiClient.convertToType(data['creatorId'], 'String');
      }
      if (data.hasOwnProperty('customerReference')) {
        obj['customerReference'] = ApiClient.convertToType(data['customerReference'], 'String');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('isValid')) {
        obj['isValid'] = ApiClient.convertToType(data['isValid'], 'Boolean');
      }
      if (data.hasOwnProperty('isPriced')) {
        obj['isPriced'] = ApiClient.convertToType(data['isPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isNoDeliveryPriced')) {
        obj['isNoDeliveryPriced'] = ApiClient.convertToType(data['isNoDeliveryPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('erpId')) {
        obj['erpId'] = ApiClient.convertToType(data['erpId'], 'Number');
      }
      if (data.hasOwnProperty('erpReference')) {
        obj['erpReference'] = ApiClient.convertToType(data['erpReference'], 'String');
      }
      if (data.hasOwnProperty('erpDocumentUrl')) {
        obj['erpDocumentUrl'] = ApiClient.convertToType(data['erpDocumentUrl'], 'String');
      }
      if (data.hasOwnProperty('totalPriceTaxesExcluded')) {
        obj['totalPriceTaxesExcluded'] = ApiClient.convertToType(data['totalPriceTaxesExcluded'], 'Number');
      }
      if (data.hasOwnProperty('totalPriceTaxesIncluded')) {
        obj['totalPriceTaxesIncluded'] = ApiClient.convertToType(data['totalPriceTaxesIncluded'], 'Number');
      }
      if (data.hasOwnProperty('isCancelled')) {
        obj['isCancelled'] = ApiClient.convertToType(data['isCancelled'], 'Boolean');
      }
      if (data.hasOwnProperty('isCancellable')) {
        obj['isCancellable'] = ApiClient.convertToType(data['isCancellable'], 'Boolean');
      }
      if (data.hasOwnProperty('isUncancellable')) {
        obj['isUncancellable'] = ApiClient.convertToType(data['isUncancellable'], 'Boolean');
      }
      if (data.hasOwnProperty('isExpired')) {
        obj['isExpired'] = ApiClient.convertToType(data['isExpired'], 'Boolean');
      }
      if (data.hasOwnProperty('isRegistered')) {
        obj['isRegistered'] = ApiClient.convertToType(data['isRegistered'], 'Boolean');
      }
      if (data.hasOwnProperty('isReadOnly')) {
        obj['isReadOnly'] = ApiClient.convertToType(data['isReadOnly'], 'Boolean');
      }
      if (data.hasOwnProperty('isVATEnabled')) {
        obj['isVATEnabled'] = ApiClient.convertToType(data['isVATEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('orderId')) {
        obj['orderId'] = ApiClient.convertToType(data['orderId'], 'String');
      }
      if (data.hasOwnProperty('canBeOrdered')) {
        obj['canBeOrdered'] = ApiClient.convertToType(data['canBeOrdered'], 'Boolean');
      }
      if (data.hasOwnProperty('quotationReferences')) {
        obj['quotationReferences'] = ApiClient.convertToType(data['quotationReferences'], [ObjectReference]);
      }
      if (data.hasOwnProperty('administrativeContactIds')) {
        obj['administrativeContactIds'] = ApiClient.convertToType(data['administrativeContactIds'], ['String']);
      }
      if (data.hasOwnProperty('technicalContactIdsByQuotationIds')) {
        obj['technicalContactIdsByQuotationIds'] = ApiClient.convertToType(data['technicalContactIdsByQuotationIds'], [QuoteTechnicalContactIdsByQuotationIds]);
      }
    }
    return obj;
  }


  id = undefined;

  creationDate = undefined;

  validationDate = undefined;

  validityDaysDuration = undefined;

  expirationDate = undefined;

  ownerId = undefined;

  ownerName = undefined;

  finalCustomerId = undefined;

  finalCustomerName = undefined;

  creatorId = undefined;

  customerReference = undefined;

  editionAllowed = undefined;

  isValid = undefined;

  isPriced = undefined;

  isNoDeliveryPriced = undefined;

  erpId = undefined;

  erpReference = undefined;

  erpDocumentUrl = undefined;

  totalPriceTaxesExcluded = undefined;

  totalPriceTaxesIncluded = undefined;

  isCancelled = undefined;

  isCancellable = undefined;

  isUncancellable = undefined;

  isExpired = undefined;

  isRegistered = undefined;

  isReadOnly = undefined;

  isVATEnabled = undefined;

  orderId = undefined;

  canBeOrdered = undefined;

  quotationReferences = undefined;

  administrativeContactIds = undefined;

  technicalContactIdsByQuotationIds = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getValidationDate() {
    return this.validationDate;
  }

  setValidationDate(validationDate) {
    this['validationDate'] = validationDate;
  }

  getValidityDaysDuration() {
    return this.validityDaysDuration;
  }

  setValidityDaysDuration(validityDaysDuration) {
    this['validityDaysDuration'] = validityDaysDuration;
  }

  getExpirationDate() {
    return this.expirationDate;
  }

  setExpirationDate(expirationDate) {
    this['expirationDate'] = expirationDate;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getFinalCustomerId() {
    return this.finalCustomerId;
  }

  setFinalCustomerId(finalCustomerId) {
    this['finalCustomerId'] = finalCustomerId;
  }

  getFinalCustomerName() {
    return this.finalCustomerName;
  }

  setFinalCustomerName(finalCustomerName) {
    this['finalCustomerName'] = finalCustomerName;
  }

  getCreatorId() {
    return this.creatorId;
  }

  setCreatorId(creatorId) {
    this['creatorId'] = creatorId;
  }

  getCustomerReference() {
    return this.customerReference;
  }

  setCustomerReference(customerReference) {
    this['customerReference'] = customerReference;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getIsValid() {
    return this.isValid;
  }

  setIsValid(isValid) {
    this['isValid'] = isValid;
  }

  getIsPriced() {
    return this.isPriced;
  }

  setIsPriced(isPriced) {
    this['isPriced'] = isPriced;
  }

  getIsNoDeliveryPriced() {
    return this.isNoDeliveryPriced;
  }

  setIsNoDeliveryPriced(isNoDeliveryPriced) {
    this['isNoDeliveryPriced'] = isNoDeliveryPriced;
  }

  getErpId() {
    return this.erpId;
  }

  setErpId(erpId) {
    this['erpId'] = erpId;
  }

  getErpReference() {
    return this.erpReference;
  }

  setErpReference(erpReference) {
    this['erpReference'] = erpReference;
  }

  getErpDocumentUrl() {
    return this.erpDocumentUrl;
  }

  setErpDocumentUrl(erpDocumentUrl) {
    this['erpDocumentUrl'] = erpDocumentUrl;
  }

  getTotalPriceTaxesExcluded() {
    return this.totalPriceTaxesExcluded;
  }

  setTotalPriceTaxesExcluded(totalPriceTaxesExcluded) {
    this['totalPriceTaxesExcluded'] = totalPriceTaxesExcluded;
  }

  getTotalPriceTaxesIncluded() {
    return this.totalPriceTaxesIncluded;
  }

  setTotalPriceTaxesIncluded(totalPriceTaxesIncluded) {
    this['totalPriceTaxesIncluded'] = totalPriceTaxesIncluded;
  }

  getIsCancelled() {
    return this.isCancelled;
  }

  setIsCancelled(isCancelled) {
    this['isCancelled'] = isCancelled;
  }

  getIsCancellable() {
    return this.isCancellable;
  }

  setIsCancellable(isCancellable) {
    this['isCancellable'] = isCancellable;
  }

  getIsUncancellable() {
    return this.isUncancellable;
  }

  setIsUncancellable(isUncancellable) {
    this['isUncancellable'] = isUncancellable;
  }

  getIsExpired() {
    return this.isExpired;
  }

  setIsExpired(isExpired) {
    this['isExpired'] = isExpired;
  }

  getIsRegistered() {
    return this.isRegistered;
  }

  setIsRegistered(isRegistered) {
    this['isRegistered'] = isRegistered;
  }

  getIsReadOnly() {
    return this.isReadOnly;
  }

  setIsReadOnly(isReadOnly) {
    this['isReadOnly'] = isReadOnly;
  }

  getIsVATEnabled() {
    return this.isVATEnabled;
  }

  setIsVATEnabled(isVATEnabled) {
    this['isVATEnabled'] = isVATEnabled;
  }

  getOrderId() {
    return this.orderId;
  }

  setOrderId(orderId) {
    this['orderId'] = orderId;
  }

  getCanBeOrdered() {
    return this.canBeOrdered;
  }

  setCanBeOrdered(canBeOrdered) {
    this['canBeOrdered'] = canBeOrdered;
  }

  getQuotationReferences() {
    return this.quotationReferences;
  }

  setQuotationReferences(quotationReferences) {
    this['quotationReferences'] = quotationReferences;
  }

  getAdministrativeContactIds() {
    return this.administrativeContactIds;
  }

  setAdministrativeContactIds(administrativeContactIds) {
    this['administrativeContactIds'] = administrativeContactIds;
  }

  getTechnicalContactIdsByQuotationIds() {
    return this.technicalContactIdsByQuotationIds;
  }

  setTechnicalContactIdsByQuotationIds(technicalContactIdsByQuotationIds) {
    this['technicalContactIdsByQuotationIds'] = technicalContactIdsByQuotationIds;
  }


}