import { defineMessages } from "react-intl";

const messages = defineMessages({
  home: { "id": "marketing.home", "defaultMessage": "Home" },



  // products: {
  //   id: "marketing.products",
  //   defaultMessage: "Produits",
  // },
  factory: { "id": "marketing.factory", "defaultMessage": "The Factory" },



  webLoginInvalidErr: { "id": "marketing.webLoginInvalidErr", "defaultMessage": "An error occurred during the operation. Try again later." },




  pageExpired: { "id": "marketing.auth.pageExpired", "defaultMessage": "This page has expired. Please repeat the procedure from the beginning." },




  passwordUpdateDone: { "id": "marketing.auth.passwordUpdateDone", "defaultMessage": "Your password has been updated successfully" },



  login: { "id": "marketing.auth.Login", "defaultMessage": "Log in" },



  loginId: { "id": "marketing.loginId", "defaultMessage": "Login ID" },



  // pricing: {
  //   id: "marketing.pricing",
  //   defaultMessage: "Tarifs",
  // },

  learnMore: { "id": "marketing.learnMore", "defaultMessage": "Learn more" },



  mountedComponents: { "id": "marketing.mountedComponents", "defaultMessage": "Assembled components" },




  onlineDemo: { "id": "marketing.onlineDemo", "defaultMessage": "Online demo" },



  // createProject: {
  //   id: "marketing.createProject",
  //   defaultMessage: "Créer un projet",
  // },
  startNewProject: { "id": "marketing.startNewProject", "defaultMessage": "Start a new project" },



  startProject: { "id": "marketing.startProject", "defaultMessage": "Start a project" },



  // faq: {
  //   id: "marketing.faq",
  //   defaultMessage: "FAQ",
  // },
  chat: { "id": "marketing.chat", "defaultMessage": "Chat" },



  // contact: {
  //   id: "marketing.contact",
  //   defaultMessage: "Contact",
  // },
  setCookies: { "id": "marketing.cookiesModal.title", "defaultMessage": "Configure Cookies" },



  TabsMonProfilProfil: { "id": "marketing.TabsMonProfilProfil", "defaultMessage": "My profile" },



  blog: { "id": "marketing.blog", "defaultMessage": "Blog" },



  lastArticle: { "id": "marketing.DernArticle", "defaultMessage": "Last articles" },



  SubscripTeaser: { "id": "marketing.auth.login.subscriptionTeaser", "defaultMessage": "100% free, you will access your personal account to consult and follow the history of your orders. All fields with an * are required." },




  passwordEightChar: { "id": "marketing.passwordEightChar", "defaultMessage": "Your password must be at least eight characters long, include one number, one lowercase letter, and one uppercase letter" },




  yourName: { "id": "marketing.yourName", "defaultMessage": "Your name" },



  yourEmail: { "id": "marketing.yourEmail", "defaultMessage": "Your mail" },



  ressources: { "id": "marketing.ressources", "defaultMessage": "Resources" },



  emptyCart: { "id": "marketing.cart.emptyCart", "defaultMessage": "Your cart is empty" },



  goToLoginPage: { "id": "marketing.auth.goToLoginPage", "defaultMessage": "Go to login" },



  sending: { "id": "marketing.auth.sending", "defaultMessage": "Sending in progress" },



  choose: { "id": "marketing.choose", "defaultMessage": "Choose" },



  // Auth
  ModifMail: { "id": "marketing.ModifMail", "defaultMessage": "Modify email" },



  emailLabel: { "id": "marketing.team.emailLabel", "defaultMessage": "Email" },



  createProject: { "id": "marketing.createProject", "defaultMessage": "Create a project" },



  selectDemo: { "id": "marketing.selectDemo", "defaultMessage": "Select a demo" },



  Fabrication: { "id": "marketing.Fabrication", "defaultMessage": "Manufacturing" },



  products: { "id": "marketing.products", "defaultMessage": "Services" },



  prices: { "id": "marketing.prices", "defaultMessage": "Quoting" },



  contact: { "id": "marketing.contact", "defaultMessage": "Contact" },



  faq: { "id": "marketing.faq", "defaultMessage": "FAQ" },



  contactUs: { "id": "marketing.contactUs", "defaultMessage": "Contact us" },



  numberOfPeople: { "id": "marketing.numberOfPeople", "defaultMessage": "Nb of people" },



  dashboard: { "id": "marketing.dashboard", "defaultMessage": "Dashboard" },



  myProjects: { "id": "marketing.myProjects", "defaultMessage": "My projects" },



  quotations: { "id": "marketing.myQuotations", "defaultMessage": "My quotes" },



  myOrders: { "id": "marketing.myOrders", "defaultMessage": "My orders" },



  deliveries: { "id": "marketing.deliveries", "defaultMessage": "Delivery / shipping" },



  invoices: { "id": "marketing.myInvoices", "defaultMessage": "My invoices" },



  stocks: { "id": "marketing.myStocks", "defaultMessage": "My stocks" },



  seeTheRest: { "id": "marketing.seeTheRest", "defaultMessage": "See more" },



  // Message présent de le helpMessage par default
  doYouNeedHelp: { "id": "marketing.doYouNeedHelp", "defaultMessage": "Need help ? We have several options for you to find the help you need." },




  questionRelatetoOurFunctionning: { "id": "marketing.questionRelatetoOurFunctionning", "defaultMessage": "If your question relates to our operation in general, you can consult our FAQ" },




  ifAskIsMoreTechnical: { "id": "marketing.ifAskIsMoreTechnical", "defaultMessage": "If your question is more technical, you're likely to find the answer in the Resources section" },




  dontFindAnswer: { "id": "marketing.dontFindAnswer", "defaultMessage": "If you can't find an answer, you can ask your question to one of our technicians" },




  help: { "id": "marketing.help", "defaultMessage": "Help" },



  checkFaq: { "id": "marketing.checkFaq", "defaultMessage": "Consult the FAQ" },



  reload: { "id": "marketing.reloadProject", "defaultMessage": "Reload projects" },



  newProject: { "id": "marketing.newProject", "defaultMessage": "New project" },



  ordering: { "id": "marketing.ordering", "defaultMessage": "Order" },



  GenerateDevisQuotes: { "id": "marketing.GenerateDevisQuotes", "defaultMessage": "Generate a quote" },



  cancel: { "id": "marketing.cancel", "defaultMessage": "Cancel" },



  back: { "id": "marketing.back", "defaultMessage": "Back" },



  ok: { "id": "marketing.ok", "defaultMessage": "Ok" },



  save: { "id": "marketing.save", "defaultMessage": "Save" },



  confirmation: { "id": "marketing.confirmation", "defaultMessage": "Confirmation" },



  confirm: { "id": "marketing.confirm", "defaultMessage": "Confirm" },



  doYouConfirmAction: { "id": "marketing.doYouConfirmAction", "defaultMessage": "Do you confirm this action ?" },



  Connexion: { "id": "marketing.Connexion", "defaultMessage": "Login" },



  Inscr: { "id": "marketing.Inscr", "defaultMessage": "Registration" },



  // Signup
  firstName: { "id": "marketing.firstName", "defaultMessage": "First name" },



  lastName: { "id": "marketing.lastName", "defaultMessage": "Name" },



  webLogin: { "id": "marketing.webLogin", "defaultMessage": "Login" },



  password: { "id": "marketing.password", "defaultMessage": "Password" },



  confirmPassword: { "id": "marketing.auth.confirmPassword", "defaultMessage": "Password Confirmation" },



  email: { "id": "marketing.email", "defaultMessage": "Email" },



  phone: { "id": "marketing.auth.phoneNb", "defaultMessage": "Phone number" },



  language: { "id": "marketing.auth.language", "defaultMessage": "Language" },



  // Profile errors
  profileEditError: { "id": "marketing.profileEditError", "defaultMessage": "An error has occurred" },



  retry: { "id": "marketing.retry", "defaultMessage": "Try again" },



  close: { "id": "marketing.close", "defaultMessage": "Close" },



  send: { "id": "marketing.send", "defaultMessage": "Send" },



  projects: { "id": "marketing.projects", "defaultMessage": "Projects" },



  project: { "id": "marketing.project", "defaultMessage": "Project" },



  description: { "id": "marketing.description", "defaultMessage": "Description" },



  quantity: { "id": "marketing.quantity", "defaultMessage": "Quantity" },



  delay: { "id": "marketing.delay", "defaultMessage": "Lead time" },



  priceNoTax: { "id": "marketing.priceNoTax", "defaultMessage": "Price excl.VAT" },



  amountHt: { "id": "marketing.amountHt", "defaultMessage": "Amount excl. VAT" },



  vat: { "id": "marketing.vat", "defaultMessage": "VAT" },



  totalTtc: { "id": "marketing.totalTtc", "defaultMessage": "Total incl. VAT" },



  selectDemo: { "id": "marketing.ControlManufacturing.selectDemo", "defaultMessage": "Select a demo" },



  selectService: { "id": "marketing.ControlManufacturing.selectService", "defaultMessage": "Select a service" },



  orderContainsUnavailableProduct: { "id": "marketing.cart.orderContainsUnavailableProduct", "defaultMessage": "Your purchases contain one or more products currently unavailable. Remove the unavailable products from the basket to be able to order." }




});

export default messages;